import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import Gallery from 'react-photo-gallery';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import axios from 'axios';

const Container = styled.div`
  padding: 95px 15px 15px 15px;

  @media (max-width: 768px) {
    padding: 95px 0 0 0;
  }

  @media (max-width: 445px) {
    padding-top: 110px;
  }
`;

const IMAGEURL = 'https://s3.amazonaws.com/kevingrimm.com/images.json';
// const IMAGEURL = '../images.json' # For testing

const ArtPage = ({ pageName }) => {
  const [imageIndex, setimageIndex] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [images, setImages] = useState(null);

  useEffect(() => {
    axios.get(IMAGEURL).then(res => {
      setImages(res.data.filter(img => img.type === pageName));
    });
  }, [pageName]);

  const openLightbox = useCallback((event, { photo, index }) => {
    setimageIndex(index);
    setViewerIsOpen(true);
  }, []);

  const columns = containerWidth => {
    let columns = 2;
    if (containerWidth >= 768) columns = 3;
    if (containerWidth >= 1000) columns = 4;
    if (containerWidth >= 1200) columns = 6;
    return columns;
  };

  if (images === null) return <div> loading </div>;

  return (
    <Container>
      <Gallery photos={images} onClick={openLightbox} direction="column" columns={columns} />
      {viewerIsOpen && (
        <Lightbox
          mainSrc={images[imageIndex].fullSizeSrc}
          nextSrc={images[(imageIndex + 1) % images.length].fullSizeSrc}
          prevSrc={images[(imageIndex + images.length - 1) % images.length].fullSizeSrc}
          onCloseRequest={() => setViewerIsOpen(false)}
          onMovePrevRequest={() => setimageIndex((imageIndex + images.length - 1) % images.length)}
          onMoveNextRequest={() => setimageIndex((imageIndex + 1) % images.length)}
        />
      )}
    </Container>
  );
};

export default ArtPage;
