const styledVariables = {
  fontStack: ('Russo One', 'Wire One', 'sans - serif'),
  primaryColor: '#1b1b1b',
  secondaryColor: '#353535',
  primaryTextColor: '#fff',
  linkColor: '#a1a1a1',
  linkHoverColor: '#fff',
  menubarLinkHoverColor: '#111',
  buttonPressColor: '#474747',

  //Breakpoints

  //Very large tablets/computers-> mainContainer slightly larger (1350px)
  screenHuge: '84.38em',

  //Large tablets/computers-> mainContainer 100% width (1200px)
  screenXl: '75em',

  //Tablets and small computers-> 4 images in a row instead of 6 in the Gallery (1000px)
  screenLg: '62.5em',

  //Small tablets-> Change to mobile view (800px)
  screenMd: '50em',

  //Small tablets and large smartphones->2 images in a row instead of 4 in the Gallery (600px)
  screenSm: '37.5em',
};

export default styledVariables;
