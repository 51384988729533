import React from 'react';
import styled from 'styled-components';

const Copyright = () => {
  const CopyrightContainer = styled.div`
    color: white;
    height: 50px;
    text-align: center;
    width: 100%;
    line-height: 3rem;
    font-size: 0.8rem;
  `;

  return (
    <CopyrightContainer>&copy; {new Date().getFullYear()} Grimm</CopyrightContainer>
  )
}

export default Copyright;
