import { Link, NavLink } from 'react-router-dom';
import styled from 'styled-components';
import React from 'react';
import styledVariables from '../styleVariables';

const TopBarContainer = styled.div`
  position: fixed;
  background-color: ${styledVariables.primaryColor};
  top: 0;
  width: 100vw;
  display: flex;
  line-height: 2rem;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
`;

const TitleBlock = styled.div`
  display: inline-block;
  margin: 2rem 0rem 2rem 2rem;
  white-space: nowrap;

  a {
    font-size: 2rem;
    font-weight: strong;

    .accent {
      color: #eb7f32;
    }
  }

  a:hover {
    color: #eb7f32;
  }

  @media (max-width: 445px) {
    margin: 1.5rem 0rem 1.5rem 1.5rem;
    white-space: normal;
  }
`;

const NavBar = styled.ul`
  display: flex;
  margin-right: 2rem;

  @media (max-width: 445px) {
    margin-right: 1.5rem;
  }
`;

const Linkies = styled.li`
  margin-left: 20px;
  white-space: nowrap;

  a:hover {
    color: #eb7f32;
  }
`;

const MenuBar = () => {
  return (
    <TopBarContainer>
      <TitleBlock>
        <Link to="/gameart">
          <div>
            <h1 style={{ fontFamily: 'museo-sans' }}>
              <span className="accent">k</span>evin <span className="accent">g</span>rimm
            </h1>
          </div>
        </Link>
      </TitleBlock>
      <NavBar>
        <Linkies>
          <NavLink
            className="nav-link"
            activeClassName="active"
            activeStyle={{
              color: '#eb7f32',
            }}
            to="/gameart"
          >
            game art
          </NavLink>
        </Linkies>
        <Linkies>
          <NavLink
            className="nav-link"
            activeClassName="active"
            activeStyle={{
              color: '#eb7f32',
            }}
            to="/fineart"
          >
            fine art
          </NavLink>
        </Linkies>
        <Linkies>
          <NavLink
            className="nav-link"
            activeClassName="active"
            activeStyle={{
              color: '#eb7f32',
            }}
            to="/about"
          >
            about
          </NavLink>
        </Linkies>
      </NavBar>
    </TopBarContainer>
  );
};

export default MenuBar;
