import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  color: white;
  padding: 95px 15% 0 15%;

  @media (max-width: 1400px) {
    padding: 95px 10% 0 10%;
  }

  @media (max-width: 1100px) {
    padding: 95px 5% 0 5%;
  }

  @media (max-width: 445px) {
    padding-top: 110px;
  }
`;

const Image = styled.img`
  float: left;
  margin-top: 2.5rem;
  width: 25%;

  @media (max-width: 1200px) {
    width: 30%;
  }

  @media (max-width: 800px) {
    display: none;
  }
`;

const AboutContainer = styled.div`
  float: right;
  margin-top: 2rem;
  margin-bottom: 1rem;
  width: 70%;

  @media (max-width: 1200px) {
    width: 65%;
  }

  @media (max-width: 800px) {
    width: 100%;
  }
`;

const Paragraph = styled.div`
  line-height: 1.7rem;
  padding-bottom: 15px;

  @media (max-width: 800px) {
    text-align: center;
  }
`;

const EmailLink = styled.a`
  font-size: 1.2rem;
  font-weight: 600;
  padding-bottom: 50px;

  &:hover {
    color: #eb7f32;
  }
`;

const About = () => {
  return (
    <Container className="clearfix">
      <Image src="https://s3.amazonaws.com/kevingrimm.com/me.jpg" />
      <AboutContainer>
        <Paragraph>
          Since I was very young, I've loved painting and drawing, and my passion for art and creativity remains as strong as ever. I began with traditional methods, enjoying the feel of a pencil or brush in my hand. As technology advanced, I discovered a preference for digital painting, while also enjoying creating characters and game assets&nbsp;in&nbsp;3D.
        </Paragraph>
        <Paragraph>
          After college, I launched my art career as an animator, concept artist, and background painter for several large animation studios in LA. These experiences prepared me well for my current work. For the past 13 years, I've been a senior artist in the game industry, understanding the intricate process required to create great games. As an art lead, I've managed highly creative teams, and together, we've launched multiple&nbsp;engaging&nbsp;games.
        </Paragraph>
        <Paragraph>
          I'm always on the lookout for exciting new challenges. If you like what you see in my portfolio, I'd love to hear&nbsp;from&nbsp;you.
        </Paragraph>
        <Paragraph>
          This is just a small sample of my work, so if there's a particular art style or subject matter you'd like to see, please let&nbsp;me&nbsp;know.
        </Paragraph>
        <Paragraph>
          <EmailLink href="mailto:KevinGrimmArtist@gmail.com" target="_top">KevinGrimmArtist@gmail.com</EmailLink>
        </Paragraph>
      </AboutContainer>
    </Container>
  );
};

export default About;
